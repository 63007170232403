import { useTranslation } from 'react-i18next';
const Home = () => {
    const { t } = useTranslation();
    return (
        <div className="page snap" id="home">
            <div className="homeBlock">
                <div className="contain">
                    <div className="homeCircle" />
                    <p>{t('home.homeDescr')}</p>
                    <div className="three">
                        <a href="#ourValues">
                            <h4>{t('home.independence')}</h4>
                        </a> <a href="#ourValues">
                            <h4>{t('home.intelligence')}</h4>
                        </a> <a href="#ourValues">
                            <h4>{t('home.integrity')}</h4>
                        </a>
                    </div>
                </div>
            </div>
            <div id="responsive_logo">
                <img src="../../images/cd_logo.jpg" alt="iCapital by Capital Dynamics" />
            </div>
            <h1> <span className="redText">{t('home.title')}</span><br />
                <span>{t('home.title2')}</span>
            </h1>
            <div id="homeDescr">
                <h2>{t('home.homeDescr')}</h2>
            </div>
            {/* <div id="mediaPreview">
                <div id="mediePrevContainter">
                    <div id="mediaScroll">
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default Home;
