import React from 'react';
import Ticker from 'react-ticker'

const TopSticky = () => {
    return (
        <div id="logoTop">
            <p>
                <a href="#home">CAPITALDYNAMICS.<span className="redText">BIZ</span></a>
                <a href="https://www.facebook.com/capitaldynamics.icapital" target="_blank" rel="noreferrer"><img src="images/facebook.png"
                    alt="Facebook" /></a>
                <a href="https://www.linkedin.com/company/2394052" target="_blank" rel="noreferrer"><img src="images/linkedin.png"
                    alt="LinkedIn" /></a>
                <a href="https://www.instagram.com/capital_dynamics" target="_blank" rel="noreferrer"><img src="images/instagram.png"
                    alt="Instagram" /></a>
                <a href="https://www.youtube.com/c/CapitalDynamics_iCapital" target="_blank" rel="noreferrer"><img src="images/youtube.png"
                    alt="YouTube" /></a>
                <a href="https://twitter.com/CAPITALDYNAMICS" target="_blank" rel="noreferrer"><img src="images/twitter.png" alt="Twitter" /></a>
                <a href="https://www.pinterest.com/capitaldynamics" target="_blank" rel="noreferrer"><img src="images/pinterest.png"
                    alt="Pinterest" /></a>
            </p>

        </div>

    );
};

export default TopSticky;
