/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import $ from 'jquery';
// TODO: React router langbar not working mobileview

const LangSwitcher = (props) => {
    let current = useTranslation().i18n.language;
    const { i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        window.location.href = `/${lng}`;
    };
    const location = useLocation();
    if (location.pathname == '/career') {
        i18n.changeLanguage('en');
        setTimeout(() => {
            $(document).scrollTop($('#career_join').offset().top);
        }, 1000);
    } else if (location.pathname !== '/en' && location.pathname !== '/ch') {
        changeLanguage('en');
    }
    return (
        <div className="lang-switch" >
            {/* <div className={window.innerWidth <= 767 ? `lang-switch-mobile` : `lang-switch`} > */}
            <a href="/en"><p className={current === 'en' ? 'active' : ''} onClick={() => changeLanguage('en')}><span style={{ textDecoration: 'none' }}>ENG</span></p></a>
            <a href="/ch"><p className={current === 'ch' ? 'active' : ''} onClick={() => changeLanguage('ch')}><span style={{ textDecoration: 'none' }}>中文</span></p></a>
            {/* <Switch>
                <Route path="/en">
                </Route>
                <Route path="/ch">
                </Route>
                <Route path="/*">
                    <Redirect to={`/en`} />
                </Route>
            </Switch> */}
        </div>
    );
    // } else {
    //     return (
    //         <div className="lang-switch-mobile" >
    //             {/* <Link to="/" style={{display: current === 'en' ? 'none' : 'block'}}><p  onClick={() => changeLanguage('en')}><a style={{ textDecoration: 'none' }}>ENG</a></p></Link>
    //             <Link to="/ch" style={{display: current === 'ch' ? 'none' : 'block'}}><p onClick={() => changeLanguage('ch')}><a style={{ textDecoration: 'none' }}>中文</a></p></Link> */}
    //             <p onClick={() => changeLanguage('ch')}><a>中文</a></p>
    //             <p onClick={() => changeLanguage('en')}><a>en</a></p>
    //         </div>
    //     );
    // }
};

export default LangSwitcher;
