import { useTranslation } from 'react-i18next';
const Titles = () => {
    const { t } = useTranslation();
    return (
        <div>
            <h1 className="page_title">
                <span className="redText">{t('Careers.title1')}</span>
                <br />{t('Careers.title2')}<br />
                {t('Careers.title3')}
                <div className="career_selection">
                    <a href="#career_view" style={{ display: 'block', height: '100%', width: '100%', textDecoration: 'none' }}>
                        <p id="section_button_1" className="career_div_selection is_active" style={{ marginBottom: '5px' }}>{t('Careers.OURTEAM.title')}
                        </p>
                    </a>
                </div>
                <div className="career_selection">
                    <a href="#career_join" style={{ display: 'block', height: '100%', width: '100%', textDecoration: 'none' }}>
                        <p id="section_button_2" className="career_div_selection">{t('Careers.OPPORTUNITIES.title')}</p>
                    </a>
                </div>
            </h1>
        </div>
    );
};

export default Titles;
