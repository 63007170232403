/* eslint-disable jsx-a11y/heading-has-content */
import { useTranslation, Trans } from 'react-i18next';

const OurHistory = () => {
    const { t } = useTranslation();
    const historyData = t('HISTORY.data', { returnObjects: true });
    return (
        < div className="page snap" id="ourHistory" >
            <h1 className="page_title">{t('HISTORY.our')} <br/><span className="redText">{t('HISTORY.history')}</span>
                <div className="arrow"> <img className="left" src="images/arrow_left.png" alt="Previous" /> <img className="right" src="images/arrow_right.png" alt="Next" /> </div>
            </h1>
            <h2 className="responsive_header" />
            <div id="timerContainer">
                <div id="timerTick">♦</div>
                <div id="timer" title="click & drag to change">
                    {historyData.map((oneHistory, i) => {
                        return <p key={i} className="timeClicker">{oneHistory.year}</p>;
                    })}
                </div>
            </div>;
            <div className="historyPhotos">
                {historyData.map((oneHistory, i) => {
                    return <div key={i} className="historyCircle" style={{ backgroundImage: `url(backgrounds/history/hist_${oneHistory.year}.jpg)` }} />;
                })}
            </div>
            <div className="pageContent">
                <div id="historyContainer">
                    {historyData.map((oneHistory, i) =>
                        <div key={i} className="historyWidget">
                            <h3>
                                <Trans i18nKey={`HISTORY.data.${i}.title`}><span></span></Trans>
                            </h3>
                            <p>
                                <Trans i18nKey={`HISTORY.data.${i}.content`}><span></span></Trans>
                            </p>
                            {
                                typeof oneHistory.title2 === 'undefined' ? '' : <h3>
                                    <Trans i18nKey={`HISTORY.data.${i}.title2`}><span></span></Trans>
                                </h3>
                            }
                            {
                                typeof oneHistory.content2 === 'undefined' ? '' : <p>
                                    <Trans i18nKey={`HISTORY.data.${i}.content2`}><span></span></Trans>
                                </p>
                            }
                        </div>
                    )}

                </div>
            </div>;
        </ div>
    );
};

export default OurHistory;
