const Content = (props) => {
    return (
        <div className="page snap" id={props.id}>
            {props.children}
            {/* <ContentTitle />
            <ContentText /> */}
        </div>
    );
};

export default Content;
