/* eslint-disable jsx-a11y/heading-has-content */
import { useTranslation, Trans } from 'react-i18next';
import Content from './Content/index';
import ContentTitle from './Content/ContentTitle';
import ContentText from './Content/ContentText';

const OurPhilosophy = () => {
    const { t } = useTranslation();
    return (
        <Content id='ourPhil'>
            <div className="bgOverlay"></div>
            <ContentTitle>
            <Trans i18nKey="OurPhilosophy.title1"></Trans><br />
            <span className="redText">{t('OurPhilosophy.title2')}</span>
            </ContentTitle>
            <h2 className="responsive_header"></h2>
            <ContentText>
                <Trans i18nKey="OurPhilosophy.content"><h3></h3></Trans>
            </ContentText>
        </Content>
    );
};

export default OurPhilosophy;
