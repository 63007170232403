import { useState, useEffect } from 'react';

// * GENERAL
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    let winHeight = height - 40;
    let winWidth = width - 40;

    //responsive
    winWidth = width <= 767 ? width : winWidth;

    return {
        width,
        height,
        winWidth,
        winHeight,
    };
};

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

// * OURHISTORY
// const useTimerI = () => {
//     const [timerI, setTimerI] = useState(0);
//     return [timerI, setTimerI];
// }

// * EXPORT
// export {useWindowDimensions, useTimerI};
export { useWindowDimensions };