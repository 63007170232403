import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

const OurOffices = () => {
    const { t } = useTranslation();
    return (
        <div className="page snap" id="ourOffices">
            <Office id={t('OFFICES.KL.id')} city={t('OFFICES.KL.city')} country={t('OFFICES.KL.country')} year={t('OFFICES.KL.year')} >
                <Trans i18nKey="OFFICES.KL.content"></Trans>
            </Office>
            <Office id={t('OFFICES.SG.id')} city={t('OFFICES.SG.city')} year={t('OFFICES.SG.year')} country={t('OFFICES.SG.country')} link="capitaldynamics.com.sg" >
                <Trans i18nKey="OFFICES.SG.content"></Trans>
            </Office>
            <Office id={t('OFFICES.AU.id')} country={t('OFFICES.AU.country')} city={t('OFFICES.AU.city')} year={t('OFFICES.AU.year')} link="capitaldynamics.com.au" >
                <Trans i18nKey="OFFICES.AU.content"></Trans>
            </Office>
            <Office id={t('OFFICES.HK.id')} country={t('OFFICES.HK.country')} city={t('OFFICES.HK.city')} year={t('OFFICES.HK.year')} link="capitaldynamics.hk" >
                <Trans i18nKey="OFFICES.HK.content"></Trans>
            </Office>
            <Office id={t('OFFICES.CN.id')} country={t('OFFICES.CN.country')} city={t('OFFICES.CN.city')} year={t('OFFICES.CN.year')} link="capitaldynamics.cn.com" >
                <Trans i18nKey="OFFICES.CN.content"></Trans>
            </Office>
        </div>
    );
};

const Office = ({ id, country, city, year, link, children }) => {
    const { t } = useTranslation();
    return (
        <div className="officeStrip" id={id}>
            <div className="bgOverlay" />
            <h1 className="officeContent">{t('OFFICES.capital')}<Trans i18nKey="OFFICES.dynamics"></Trans><br />
                <span className="redText">{country}</span>
            </h1>
            <div className="pageContent officeContent">
                {children}
                {id === "officeKL" ?
                    <p>Visit <b><a href="https://icapital.biz" target="_blank" rel="noreferrer">www.icapital.biz</a></b> or <b><a href="https://www.cdam.biz" target="_blank" rel="noreferrer">www.cdam.biz</a></b> for more.</p> :
                    <p>Visit <b><a href={`https://www.${link}`} target="_blank" rel="noreferrer">www.{link}</a></b> for more.</p>
                }
            </div>
            <div className="slideUp">
                <div className="redLine" />
                <div className="cityCircle">
                    <h3>{city}</h3>
                    <p>{t('OFFICES.since')} {year}{t('OFFICES.since2')}</p>
                </div>
            </div>
        </div>
    );
};

export default OurOffices;
