/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';

import { useTranslation } from 'react-i18next';

const Announcements = () => {
    const { t } = useTranslation();
    const pressList = t('EVENT.pressList', { returnObjects: true });
    const mediaList = t('EVENT.mediaList', { returnObjects: true });
    return (
        <div className="page not snap" id="media">
            <h1 className="page_title">{t('EVENT.title')}
                {
                    t('EVENT.title2') === '' ? '' : <span className="redText"><br />{t('EVENT.title2')}</span>
                }
            </h1>
            <h2 className="responsive_header" />
            <div className="pageContent" style={{ marginBottom: 0 }}>
                <p>{t('EVENT.subtitle')}</p>
            </div>
            <div id="mediaMenu">
                <div id="updates">
                    <a href="#updates">
                        <h4 className="active updatesBtn">{t('EVENT.btn1')}</h4>
                    </a>
                </div>
                <div id="press">
                    <a href="#press">
                        <h4 className="pressBtn">{t('EVENT.btn2')}</h4>
                    </a>
                </div>
            </div>
            <div id="pressClipsArea">
                <table id="pressClipsTable">
                    <tbody>
                        {
                            pressList.map((press, i) => {
                                return (<OnePress key={i} {...press} />);
                            })
                        }
                    </tbody>
                </table>
                <p className="moreMedia" style={{ textAlign: 'center' }}>{t('EVENT.moremedia')} <a href="https://www.icapital.biz/public/in_the_media" target="_blank" rel="noreferrer">{t('EVENT.here')}</a>.</p>
            </div>
            <div className="mediaArea">
                <ul id="mediaPool" name="mediaPool">
                    {
                        mediaList.map((media, i) => {
                            return (<OneMedia key={i} {...media} />);
                        })
                    }
                </ul>
                <p className="moreMedia" style={{ textAlign: 'center' }}>{t('EVENT.morevideo')} <a href="https://www.icapital.biz/public/in_the_media" target="_blank" rel="noreferrer">{t('EVENT.here')}</a>.</p>
            </div>
        </div>
    );
};

export default Announcements;

const OnePress = (press) => {
    return (
        <tr>
            <td>
                <p>
                    <b>{press[1]}</b><br/>
                    {press[0]}
                </p>
            </td>
            <td>
                <p></p>
            </td>
            <td>
                <p><a href={press[2]} target="_blank" rel="noreferrer">{press[3]}</a></p>
            </td>
        </tr>
    );
};

const OneMedia = (media) => {
    return (
        <a href={media[0]} target="_blank" rel="noreferrer">
            <li className="mediaThumb" style={{ background: `url(${media[1]})` }}>
                <div className="bgOverlay">
                    <h3>{media[2]}</h3>
                </div>
            </li>
        </a>
    );
};