import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    // load translation using http -> see /public/locales, with backend loadpath below
    .use(Backend)
    // detect user language with detection option below
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: (prop) => { //custom path for all trad/simp chinese to 'ch'
                let loadedLang = prop[0] === 'zh-CN' || prop[0] === 'zh-TW' || prop[0] === 'zh' ? 'ch' : prop[0];
                return `/locales/${loadedLang}/translation.json`;
              },
        },
        detection: {
            // order and from where user language should be detected
            order: ['path', 'localStorage', 'navigator'],

            lookupFromPathIndex: 0,
            lookupLocalStorage: 'i18nextLng',

            // cache user language on
            caches: ['localStorage'],
        },
        fallbackLng: 'en', //default language(if all detection not found)
        // debug: true,
        react: {
            // useSuspense: false
            transKeepBasicHtmlNodesFor : ['br', 'strong', 'i', 'p', 'ul', 'li', 'a', 'sup', 'em', 'span', 'ol'],
        }
    });

export default i18n;