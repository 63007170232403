const SpecialBanner = () => {
    return (
        <div id="media1">
            <Items imgUrl="https://funds.icapital.biz/images/newsletter_banner.jpg" link="https://www.icapital.biz/" />
        </div>
    );
};

const Items = ({ imgUrl, link }) => {
    return (
        <div style={{ borderBottom: '5px solid #ed1c24', backgroundColor: 'white', marginBottom: '15px', padding: '10px 10px' }}>
            <a href={link} target="_blank" rel="noreferrer">
                <img src={imgUrl} alt="" style={{ width: '100%', height: 'auto' }} />
            </a>
        </div>
    );
};

export default SpecialBanner;
