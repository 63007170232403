/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useWindowDimensions } from '../../State';
import { useTranslation } from 'react-i18next';

const CurrentTeam = () => {
    const { t } = useTranslation();
    const teams = t('Careers.OURTEAM.teams', { returnObjects: true });
    const peopleColumns = useWindowDimensions().width > 1024 ? 3 : 2;
    const teamsJSX = teams.reduce((accumulator, current, i) => {
        if (i % peopleColumns === 0) accumulator.push([current]);
        else accumulator[accumulator.length - 1].push(current);
        return accumulator;
    }, []);
    return (
        <div className="carrerView" id="career_view">
            <div id="careerflex" >
                <div>
                    <div>
                        <img style={{ width: '300px', height: '300px' }} src="images/update/ttb.png" />
                        <div style={{ textAlign: 'center', fontWeight: 550, position: 'absolute', margin: '-40px 0px 0px 0px', backgroundColor: 'red', width: '165px', height: '36px', fontSize: 'x-large', color: 'white' }}>
                            <span style={{ marginTop: '5px', fontFamily: '"Times New Roman"', display: 'inline-block' }}>
                                {t('Careers.OURTEAM.TTB.name')}
                            </span>
                        </div>
                        <p style={{ fontFamily: '"Times New Roman"', fontWeight: 580, fontSize: 'medium', color: 'red' }}>
                            {t('Careers.OURTEAM.TTB.pos')}
                        </p>
                    </div>
                </div>
                <div style={{ fontSize: '15px' }} className="jobContactTile">
                    <p style={{ lineHeight: '1.2', fontFamily: 'Arial' }}>{t('Careers.OURTEAM.TTB.content1')}</p>
                    <p style={{ lineHeight: '1.2', fontFamily: 'Arial' }}>{t('Careers.OURTEAM.TTB.content2')}</p>
                    <p style={{ lineHeight: '1.2', fontFamily: 'Arial' }}>{t('Careers.OURTEAM.TTB.content3')}</p>
                </div>
            </div>
            <div className="team">
                <h3>{t('Careers.OURTEAM.TTB.h3')}</h3>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        teamsJSX.map((oneRow, i) => (
                            <div key={i} className="career_people_container">
                                {oneRow.map((onePerson, ii) => (<PeopleBox key={ii} {...onePerson} />))}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div >
    );
};

const PeopleBox = ({ name, post, joined, qualification, imgurl }) => {
    const { t } = useTranslation();
    return (
        <div className="people_box">
            <img className="people_image_box" src={imgurl} />
            <div className="career_people_detail">
                <h4>{name}</h4>
                <h5>{post}</h5>
                <p>{t('Careers.OURTEAM.Joined')} {joined}</p>
                <p>{qualification}</p>
            </div>
        </div>
    );
};

export default CurrentTeam;