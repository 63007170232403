/* eslint-disable react/style-prop-object */
import React from 'react'
import TopSticky from './Overlay/TopSticky'
import SideMenu from './Overlay/SideMenu'
import LangSwitcher from './Overlay/LangSwitcher'
import AlertTicker from './Overlay/TopTicker.js'
import { useTranslation } from "react-i18next";

const Overlay = () => {
    let current = useTranslation().i18n.language;
    return (
        <>
            <AlertTicker/>
            <TopSticky />
            <div id="logoSide"><img src={current === 'en' ? "images/cd_logo.jpg" : 'images/cd_logo_ch.jpg'} alt="iCapital by Capital Dynamics"/></div>
            <SideMenu />
            <LangSwitcher />
            <span id="eng-page-flag" style={{display: 'none'}} />
        </>
    )
}

export default Overlay
