/* eslint-disable jsx-a11y/heading-has-content */
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from 'react';
import Content from './Content/index';
import ContentTitle from './Content/ContentTitle';
import ContentText from './Content/ContentText';
import $ from 'jquery';

const OurProducts = () => {
    const { t } = useTranslation();

    useEffect(() => {
        var productPool = [];
        var productIndex = 0;
        $('.product').each(function () {
            productPool.push($(this));
        });
        $('#productContainer').width((productPool.length) * 420);
        var productdiv_width = 420;
        function slideProduct(index) {
            if($(window).width() < 420) {
                $('#productContainer').animate({
                    left: -(index * $(window).width()) + 'px'
                });    
            }
            else {
                $('#productContainer').animate({
                    left: -(index * productdiv_width) + 'px'
                });
            }
            $('#productContainer').css({
                height: (productPool[index].css('height'))
            });
        }
        $("#ourProducts .arrow .left").off("click");
        $("#ourProducts .arrow .right").off("click");
        $('#ourProducts .arrow .left').click(function (e) {
            if (productIndex <= 0) {
                productIndex = ((productPool.length) - 1);
            } else {
                productIndex--;
            }
            $('html, body').animate({
                scrollTop: ($('#ourProducts').offset().top - 10)
            }, 500);
            slideProduct(productIndex);
        });

        $('#ourProducts .arrow .right').click(function (e) {
            if (productIndex >= ((productPool.length) - 1)) {
                productIndex = 0;
            } else {
                productIndex++;
            }
            $('html, body').animate({
                scrollTop: ($('#ourProducts').offset().top - 10)
            }, 500);
            slideProduct(productIndex);
        });
    });

    return (
        <Content id='ourProducts'>
            <ContentTitle>
                <Trans i18nKey="OurProducts.title1"></Trans><br />
                <span className="redText">{t('OurProducts.title2')}</span>
                <div className="arrow">
                    <img className="left" src="images/arrow_left.png" alt="Previous" />
                    <img className="right" src="images/arrow_right.png" alt="Next" />
                </div>
            </ContentTitle>
            <h2 className="responsive_header"></h2>
            <ContentText>
                <div id="productContainer">
                    <Product id="1" imgURL="images/spr_adv.png" alt="Investment Advisory - iCapital.biz" title={t('OurProducts.prodtitle1')} hreflink="icapital.biz" moreinfo="moreinfo3">
                        <Trans i18nKey="OurProducts.prod1"><span className="italics">i</span></Trans>
                    </Product>
                    <Product id="2" alt="icapital.biz Berhad (ICAP)" title={t('OurProducts.prodtitle2')} hreflink="funds.icapital.biz" moreinfo="moreinfo2">
                        <Trans i18nKey="OurProducts.prod2"><span className="italics">i</span></Trans>
                    </Product>
                    <Product id="3" alt="i Capital Global Fund" title={t('OurProducts.prodtitle3')} hreflink="funds.icapital.biz" moreinfo="moreinfo2">
                        <Trans i18nKey="OurProducts.prod3"><span className="italics">i</span></Trans>
                    </Product>
                    <Product id="4" alt="i Capital International Value Fund" title={t('OurProducts.prodtitle4')} hreflink="funds.icapital.biz" moreinfo="moreinfo2">
                        <Trans i18nKey="OurProducts.prod4"><span className="italics">i</span></Trans>
                    </Product>
                    <Product id="5" alt="i Capital China Fund" title={t('OurProducts.prodtitle5')} hreflink="funds.icapital.biz" moreinfo="moreinfo2">
                        <Trans i18nKey="OurProducts.prod5"><span className="italics">i</span></Trans>
                    </Product>
                    <Product id="7" alt="i Capital Asia-Pacific ex-Japan BTB Fund" title={t('OurProducts.prodtitle7')} hreflink="funds.icapital.biz" moreinfo="moreinfo2">
                    <Trans i18nKey="OurProducts.prod7"><span className="italics">i</span></Trans>
                    </Product>
                    <Product id="8" alt="i Capital ASEAN BTB Fund" title={t('OurProducts.prodtitle8')} hreflink="funds.icapital.biz" moreinfo="moreinfo2">
                        <Trans i18nKey="OurProducts.prod8">
                            <span className="italics">i</span>
                        </Trans>
                    </Product>
                    <Product id="6" alt="Individually Managed Accounts" title={t('OurProducts.prodtitle6')} hreflink="funds.icapital.biz" moreinfo="moreinfo1">
                        <Trans i18nKey="OurProducts.prod6"></Trans>
                    </Product>
                </div>
            </ContentText>
        </Content>
    );
};

export default OurProducts;

const Product = ({ id, imgURL, alt, title, hreflink, children, moreinfo }) => {
    const { t } = useTranslation();
    const shouldItalic = title[0] === 'i';
    return (
        <div className="product" product-id={id}> 
            {imgURL ? 
            <img 
                src={imgURL} 
                className="prodImg"
                alt={alt} 
            />
            : null
            }
            <h3 style={{}}>
                {
                    shouldItalic ?
                        <span className="italics">i</span> :
                        ''
                }
                {shouldItalic ? title.slice(1, title.length) : title}
            </h3>
            {children}
            <p>{t(`OurProducts.${moreinfo}`)} <a href={`https://${hreflink}/`} target="_blank" rel="noreferrer">{hreflink}</a></p>
        </div>
    );
};