/* eslint-disable jsx-a11y/heading-has-content */
// TODO commonmethods? Add margin top
import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Content from './Content/index';
import ContentTitle from './Content/ContentTitle';
import ContentText from './Content/ContentText';
import $ from 'jquery';

const OurValues = (State) => {
    const { t } = useTranslation();
    const { useWindowDimensions } = State.State;
    const { winWidth, winHeight } = useWindowDimensions();
    useEffect(() => {
        const setValuesBlock = () => {
            var y1 = $('#ourValues').height();
            var y2 = $('#valuesBlock').height();
            $('#valuesBlock').css('margin-top', ((y1 / 2) - (y2 / 2)) + 'px');
            $('#valueImageBlock').css('margin-top', ($('#valuesBlock').css('margin-top')));
        };
        setTimeout(function () { setValuesBlock(); }, 1000);
        showValue('independence', 'backgrounds/values/value_fish.jpg');
        showValue('intelligence', 'backgrounds/values/value_ants.jpg');
        showValue('integrity', 'backgrounds/values/value_sheep.jpg');
        closeValue();
    });
    const showValue = (itemClass, imgURL) => {
        $(`.valueImage.${itemClass}`).click(function () {
            $('html, body').animate({
                scrollTop: ($('#ourValues').offset().top - 10)
            }, 100);
            $('.valuePlatform').css('background-image', `url(${imgURL})`);
            $('.valuePlatform').animate({
                width: (winWidth + 'px'),
                height: (winWidth + 'px'),
                borderRadius: (winWidth / 2 + 'px'),
                left: 0,
                top: ((winHeight - winWidth) / 2 + 'px')
            }, 500, function () {
                $(`.valueText.${itemClass}`).slideDown(700);
                $('.valuePlatform').css({
                    borderRadius: 0,
                });

            });

        });
    };
    const closeValue = () => {
        $('.valueText').click(function () {
            $('.valuePlatform').css({
                width: 0,
                height: 0,
                left: (winWidth / 2 + 'px'),
                top: (winHeight / 2 + 'px')
            });
            $('.valueText').hide();
        });

        $('.homeBlock h4').click(function () {
            $('.valuePlatform').css({
                width: 0,
                height: 0,
                left: (winWidth / 2 + 'px'),
                top: (winHeight / 2 + 'px')
            });
            $('.valueText').hide();
        });
    };
    return (
        <Content id='ourValues'>
            <ContentTitle>
                <Trans i18nKey="OurValues.title1"></Trans><br />
                <span className="redText">{t('OurValues.title2')}</span><br />
                {t('OurValues.title3')}
            </ContentTitle>
            <h2 className="responsive_header"></h2>
            <div id="valueImageBlock">
                <div className="valueImage independence"></div>
                <div className="valueImage intelligence"></div>
                <div className="valueImage integrity"></div>
            </div>
            <ContentText id="valuesBlock">
                <div className="value">
                    <h3>{t('OurValues.independence.title')}</h3>
                    <p>{t('OurValues.independence.content')}</p>
                </div>
                <div className="value">
                    <h3>{t('OurValues.intelligence.title')}</h3>
                    <p>{t('OurValues.intelligence.content')}</p>
                </div>
                <div className="value">
                    <h3>{t('OurValues.integrity.title')}</h3>
                    <p>{t('OurValues.integrity.content')}</p>
                </div>
            </ContentText>
            <div className="valuePlatform"></div>
            <div className="valueText independence">
                <h1><Trans i18nKey="OurValues.independence.longtitle"></Trans></h1>
                <ContentText>
                    <Trans i18nKey="OurValues.independence.long"></Trans>
                </ContentText>
            </div>
            <div className="valueText intelligence">
                <h1><Trans i18nKey="OurValues.intelligence.longtitle"></Trans></h1>
                <ContentText>
                    <Trans i18nKey="OurValues.intelligence.long"></Trans>
                </ContentText>
            </div>
            <div className="valueText integrity">
                <h1><Trans i18nKey="OurValues.integrity.longtitle"></Trans></h1>
                <ContentText>
                    <Trans i18nKey="OurValues.integrity.long"></Trans>
                </ContentText>
            </div>
        </Content>
    );
};

export default OurValues;
