/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/heading-has-content */
import Titles from './Careers/Titles';
import CurrentTeam from './Careers/CurrentTeam';
import JobOpenings from './Careers/JobOpenings';

const Careers = () => {
    return (
        <div className="page snap" id="careers">
            <div id="whiteback" style={{ borderRadius: '5px', backgroundColor: 'white', opacity: '0.8',  position: 'absolute', zIndex: 0 }}>
            </div>
            <Titles />
            <h2 className="responsive_header"></h2>
            <div className="ourFuture">
                <CurrentTeam />
                <JobOpenings />
            </div>
        </div>
    );
};

export default Careers;
