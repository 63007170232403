import $ from 'jquery';

// * EXECUTE DEFAULT


// * AboutUs
const set_title = () => {
    $(".page").each(function () {
        let content;
        if ($(window).width() <= 767 && $(this).find("h1.page_title").html() !== "") {
            content = $(this).find("h1.page_title").html();
            $(this).find("h2.responsive_header").html(content);
            $(this).find("h1.page_title").html("");
        } else if ($(window).width() > 767 && $(this).find("h2.responsive_header").html() !== "") {
            content = $(this).find("h2.responsive_header").html();
            $(this).find("h1.page_title").html(content);
            $(this).find("h2.responsive_header").html("");
        }
    });
};

// * EXPORT
export { set_title };