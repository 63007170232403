import { useTranslation } from 'react-i18next';
import AlertTicker from './Overlay/TopTicker';

const MobileNav = () => {
    const { t } = useTranslation();
    const menuList = t('MobileNav', { returnObjects: true });
    const menuHREF = ['home', 'aboutUs', 'ourValues', 'ourPhil', 'careers', 'ourProducts', 'ourOffices', 'ourHistory', 'careers', 'media', 'contact'];
    return (
        <>
        <nav id='responsive_nav' className="navbar navbar-default navbar-fixed-top" role="navigation">
            <div className="container-fluid">
                {/* <!-- Brand and toggle get grouped for better mobile display --> */}
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" style={{ zIndex: 9999 }}>
                        <span className="sr-only">Toggle navigation</span>
                        {
                            [...Array(3)].map((_, i) => {
                                return <span key={i} className="icon-bar"></span>;
                            })
                        }
                    </button>
                    <AlertTicker/>
                </div>
                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav">
                        {
                            menuList.map((oneTitle, i) => {
                                return <Items key={i} hrefs={menuHREF[i]} title={oneTitle} activeClass={oneTitle === 'HOME' ? 'active' : ''} />;
                            })
                        }
                    </ul>
                </div>
            </div>
        </nav>
        </>
    );
};

const Items = ({ activeClass, title, hrefs }) => {
    return (
        <li className="nav-item" >
            <a className={'nav-link scroll ' + activeClass} href={`#${hrefs}`}>{title}</a>
        </li >
    );
};

export default MobileNav;
