/* eslint-disable jsx-a11y/heading-has-content */
import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Content from './Content/index';
import ContentTitle from './Content/ContentTitle';
import ContentText from './Content/ContentText';

const AboutUs = ({ CommonMethods }) => {
    useEffect(() => {
        CommonMethods.set_title();
    });
    const { t } = useTranslation();
    return (
        <Content id='aboutUs'>
            <div className="bgOverlay"></div>
            <ContentTitle>
                {t('AboutUs.Who')} <br />
                <span className="redText">{t('AboutUs.we')}</span><br />
                {t('AboutUs.are')}
            </ContentTitle>
            <h2 className="responsive_header"></h2>
            <ContentText>
                {/* Trans used for complex HTML nodes translation */}
                <Trans i18nKey="AboutUs.content">
                    <em>i </em>
                </Trans>
            </ContentText>
        </Content>
    );
};

export default AboutUs;
