import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import $ from 'jquery';

const JobOpenings = () => {
    let { t } = useTranslation();
    const jobs = t('Careers.OPPORTUNITIES.desc_asc', { returnObjects: true });


    useEffect(() => {
        let jobPool = [];
        let descrPool = [];
        $('.jobTitle').each(function () {
            jobPool.push($(this));
        });
        $('.jobDescr').each(function () {
            descrPool.push($(this));
        });
        $('.careerTile').click(function () {
            var _call = $(this).attr('data-call');
            $('.jobDept').hide();
            $('#' + _call).show();
        });
        $('.jobTitle').click(function () {
            $('.jobDescr').hide();

            let i = $('.jobTitle').index(this);
            descrPool[i].show();
            $('#jobDescreHeading').html(jobPool[i].html());
        });
    });

    return (
        <div className="careerJoin" id="career_join">
            <div id="careerPlatform">
                <div className="closeBtn" title="close">
                    <p>X</p>
                </div>
                {
                    jobs.map((category, index) => {
                        return (
                            <div key={index} className="jobDept" id={category.id} style={{ display: 'none' }}>
                                <h3>{category.name}</h3>
                                {
                                    category.details.map((onejob, i) => {
                                        return (
                                            <p key={i}>&gt; <a className="jobTitle" href="#careers">{onejob.jobname}</a></p>
                                        );
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
            <div id="jobPlatform">
                <div className="closeBtn" title="close">
                    <p>X</p>
                </div>
                <h3 id="jobDescreHeading">Job Title</h3>
                {
                    jobs.map((category, index) => {
                        return category.details.map((onejob, i) => {
                            return (
                                <div key={i} className="jobDescr">
                                    <Trans i18nKey={`Careers.OPPORTUNITIES.desc_asc.${index}.details.${i}.jobdetail`}>
                                        <p className="listHeader"></p>
                                    </Trans>
                                </div>
                            );
                        });
                    })
                }
            </div>

            <br />
            <h3 style={{ fontWeight: 800, textAlign: 'center' }}>{t('Careers.OPPORTUNITIES.careerhome')}</h3>
            <div className="career_content_container">
                {
                    jobs.map((category, index) => {
                        return (
                            <div key={index} className="careerTile" data-call={category.id}>
                                <p>{category.name}</p>
                            </div>
                        );
                    })
                }

            </div>

        </div>
    );
};

export default JobOpenings;
