import logo from './cd_logo.jpg'; // with import
import './LoadingScreen/LoadingScreen.css'

const LoadingScreen = () => {
    return (
        <div style={{ backgroundColor: "#211D1E", width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={logo} id="zooming" alt="load" />
        </div>
    );
};

export default LoadingScreen;
