import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-49711533-2');

const SideMenu = () => {
    const { t } = useTranslation();
    const currentLang = useTranslation().i18n.language;

    let currentSec = 'home';
    const setGAView = (page, section = '', subsec = '') => {
        ReactGA.ga('send', 'pageview', currentLang + '/' + page + section + subsec);
    };
    setGAView(currentSec);
    window.onscroll = function () {
        let _home = document.getElementById('home').getBoundingClientRect().top;
        let _aboutUs = document.getElementById('aboutUs').getBoundingClientRect().top;
        let _ourValues = document.getElementById('ourValues').getBoundingClientRect().top;
        let _ourPhil = document.getElementById('ourPhil').getBoundingClientRect().top;
        let _ourProducts = document.getElementById('ourProducts').getBoundingClientRect().top;
        let _careers = document.getElementById('careers').getBoundingClientRect().top;
        let _ourOffices = document.getElementById('ourOffices').getBoundingClientRect().top;
        let _ourHistory = document.getElementById('ourHistory').getBoundingClientRect().top;
        let _media = document.getElementById('media').getBoundingClientRect().top;
        let _contact = document.getElementById('contact').getBoundingClientRect().top;
        let sections = [_home, _aboutUs, _ourValues, _ourPhil, _ourProducts, _careers, _ourOffices, _ourHistory, _media, _contact];
        let sectionsName = ['home', 'aboutUs', 'ourValues', 'ourPhil', 'ourProducts', 'careers', 'ourOffices', 'ourHistory', 'media', 'contact'];

        let scrollSec;
        sections.forEach((section, i) => {
            if (section <= 0) {
                scrollSec = sectionsName[i];
            }
        });
        if (scrollSec !== currentSec) {
            currentSec = scrollSec;
            setGAView(currentSec);
        }
    };

    return (
        <div id="menu">
         <a href="#home">
                <div className="sub">
                    <p>{t('SideMenu.HOME')}</p>
                </div>
            </a> <a href="#aboutUs">
                <div className="sub">
                    <p>{t('SideMenu.ABOUT')}</p>
                </div>
            </a> <a href="#ourValues">
                <div className="sub">
                    <p>{t('SideMenu.PHILOSOPHY')}</p>
                </div>
            </a> <a href="#ourPhil">
                <div className="sub">
                    <p>{t('SideMenu.INVESTMENT')}</p>
                </div>
            </a><a href="#ourProducts">
                <div className="sub">
                    <p>{t('SideMenu.PRODUCTS')}</p>
                </div>
            </a> <a href="#careers">
                <div className="sub">
                    <p>{t('SideMenu.WORK')}</p>
                </div>
            </a> <a href="#ourOffices">
                <div className="sub">
                    <p>{t('SideMenu.OFFICES')}</p>
                </div>
            </a> <a href="#ourHistory">
                <div className="sub">
                    <p>{t('SideMenu.HISTORY')}</p>
                </div>
            </a> <a href="https://events.icapital.biz/" target='_blank'>
                <div className="sub">
                    <p  className='events'>{t('SideMenu.EVENTSITE')}</p>
                </div>
            </a> <a href="#media">
                <div className="sub">
                    <p>{t('SideMenu.EVENT')}</p>
                </div>
            </a> <a href="#contact">
                <div className="sub">
                    <p>{t('SideMenu.CONTACT')}</p>
                </div>
            </a>
        </div>
    );
};

export default SideMenu;
