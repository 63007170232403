import React from "react";
import { AlertTriangle } from "lucide-react";
import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  alertContainer: {
    backgroundColor: "#f00", // red-600
    color: "#ffffff", // white
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    position: "fixed",
    right: "15%",
    top: "0",
    width: "40%",
    '@media (max-width: 767px)': {
        right: "0",
        width: "100%",
        paddingBottom: "0",
    }
  },
  alertLabel: {
    flexShrink: 0,
    backgroundColor: "#000000", // black
    color: "#facc15", // yellow-400
    fontWeight: "bold",
    padding: "8px 48px 8px 24px",
    clipPath: "polygon(0 0, 75% 0, 100% 100%, 0% 100%)", // Creates a trapezoidal shape
    position: "relative",
    top: "8px", // Offset to overflow slightly at the bottom
    '@media (max-width: 767px)': {
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", // Creates a trapezoidal shape
        padding: "8px",
        paddingLeft: "12%",
        margin: "0",
        top: "0", // Offset to overflow slightly at the bottom
    }
  },
  marqueeContainer: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
  },
  marquee: {
    display: "flex",
    whiteSpace: "nowrap",
    animation: "$scroll 1000s linear infinite", // Adjusted to make scrolling seamless
    maxWidth: "none",
    gap: "50px", // Adjust gap to create space between messages
  },
  alertLink: {
    display: "inline-block",
    textDecoration: "none",
    fontWeight: "bold",
    color: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "@keyframes scroll": {
    "0%": {
      transform: "translateX(0%)",
    },
    "100%": {
      transform: "translateX(-100%)", // Moves the entire length of content for smooth looping
    },
  },
});

const AlertTicker = () => {
  const classes = styles();

  return (
    <div className={classes.alertContainer}>
      <div className={classes.alertLabel}>INVESTOR ALERT</div>
      <div className={classes.marqueeContainer}>
        <div className={classes.marquee}>
          {/* Repeating content to ensure continuous scrolling effect */}
          {Array.from({ length: 99 }).map((_, index) => (
            <a
              key={index}
              href="https://funds.icapital.biz/announcement/content/Scams-notification.pdf"
              className={classes.alertLink}
              target="_blank"
            >
              <AlertTriangle className="h-5 w-5 mr-2" />
              BEWARE OF SCAMS AND IMPERSONATIONS!!! CLICK HERE FOR MORE
              INFORMATION.
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlertTicker;

